const Testing = () => {
  return <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Testing" data-sentry-source-file="Testing.jsx">
      <rect width="160" height="160" rx="8" fill="#D050E9" fillOpacity="0.5" data-sentry-element="rect" data-sentry-source-file="Testing.jsx" />
      <path d="M38.5 53C47.0604 53 54 46.0604 54 37.5C54 28.9396 47.0604 22 38.5 22C29.9396 22 23 28.9396 23 37.5C23 46.0604 29.9396 53 38.5 53Z" stroke="#05293D" strokeWidth="4" strokeMiterlimit="10" data-sentry-element="path" data-sentry-source-file="Testing.jsx" />
      <path d="M36.0009 45.3624L28.2285 37.59L30.8691 34.9494L36.0009 40.0812L46.2421 29.8438L48.8827 32.4844L36.0009 45.3624Z" fill="#05293D" data-sentry-element="path" data-sentry-source-file="Testing.jsx" />
      <path d="M38.5 96C47.0604 96 54 89.0604 54 80.5C54 71.9396 47.0604 65 38.5 65C29.9396 65 23 71.9396 23 80.5C23 89.0604 29.9396 96 38.5 96Z" stroke="#05293D" strokeWidth="4" strokeMiterlimit="10" data-sentry-element="path" data-sentry-source-file="Testing.jsx" />
      <path d="M36.0009 88.3624L28.2285 80.59L30.8691 77.9494L36.0009 83.0812L46.2421 72.8438L48.8827 75.4844L36.0009 88.3624Z" fill="#05293D" data-sentry-element="path" data-sentry-source-file="Testing.jsx" />
      <path d="M38.5 139C47.0604 139 54 132.06 54 123.5C54 114.94 47.0604 108 38.5 108C29.9396 108 23 114.94 23 123.5C23 132.06 29.9396 139 38.5 139Z" stroke="#05293D" strokeWidth="4" strokeMiterlimit="10" data-sentry-element="path" data-sentry-source-file="Testing.jsx" />
      <line x1="69" y1="37" x2="138" y2="37" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="Testing.jsx" />
      <line x1="69" y1="80" x2="138" y2="80" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="Testing.jsx" />
      <line x1="69" y1="123" x2="138" y2="123" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="Testing.jsx" />
    </svg>;
};
export default Testing;