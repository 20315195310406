const Buy = () => {
  return <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Buy" data-sentry-source-file="Buy.jsx">
      <rect width="160" height="160" rx="8" fill="#4C78EC" fillOpacity="0.5" data-sentry-element="rect" data-sentry-source-file="Buy.jsx" />
      <path d="M72 57.3262L81.603 66.9292" stroke="#05293D" strokeWidth="4" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="Buy.jsx" />
      <path d="M91.4521 57.3984L81.8492 67.0014" stroke="#05293D" strokeWidth="4" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="Buy.jsx" />
      <path d="M81.8945 35L81.8945 66" stroke="#05293D" strokeWidth="4" data-sentry-element="path" data-sentry-source-file="Buy.jsx" />
      <circle cx="61" cy="115" r="9" stroke="#05293D" strokeWidth="4" data-sentry-element="circle" data-sentry-source-file="Buy.jsx" />
      <circle cx="105" cy="115" r="9" stroke="#05293D" strokeWidth="4" data-sentry-element="circle" data-sentry-source-file="Buy.jsx" />
      <path d="M23 56H42.495L54.6062 96H81.6484H112.191L121.5 65.0667" stroke="#05293D" strokeWidth="4" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="Buy.jsx" />
    </svg>;
};
export default Buy;