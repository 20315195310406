const TCO = () => {
  return <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="TCO" data-sentry-source-file="TCO.jsx">
      <rect width="160" height="160" rx="8" fill="#40FB76" fillOpacity="0.5" data-sentry-element="rect" data-sentry-source-file="TCO.jsx" />
      <line x1="26" y1="80" x2="134" y2="80" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="TCO.jsx" />
      <line x1="80" y1="26" x2="80" y2="134" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="TCO.jsx" />
      <line x1="61" y1="53" x2="38" y2="53" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="TCO.jsx" />
      <line x1="122" y1="105" x2="99" y2="105" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="TCO.jsx" />
      <line x1="122" y1="116" x2="99" y2="116" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="TCO.jsx" />
      <line x1="122" y1="53" x2="99" y2="53" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="TCO.jsx" />
      <line x1="58.2684" y1="118.135" x2="41.8635" y2="101.73" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="TCO.jsx" />
      <line x1="110" y1="65" x2="110" y2="42" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="TCO.jsx" />
      <line x1="41.8631" y1="118.588" x2="58.268" y2="102.183" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="TCO.jsx" />
    </svg>;
};
export default TCO;