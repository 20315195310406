const Connectors = () => {
  return <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Connectors" data-sentry-source-file="Connectors.jsx">
      <rect width="160" height="160" rx="8" fill="#17E8DA" fillOpacity="0.5" data-sentry-element="rect" data-sentry-source-file="Connectors.jsx" />
      <circle cx="47" cy="79" r="9" stroke="#05293D" strokeWidth="4" data-sentry-element="circle" data-sentry-source-file="Connectors.jsx" />
      <circle cx="64" cy="56" r="7" stroke="#05293D" strokeWidth="4" data-sentry-element="circle" data-sentry-source-file="Connectors.jsx" />
      <circle cx="64" cy="104" r="9" stroke="#05293D" strokeWidth="4" data-sentry-element="circle" data-sentry-source-file="Connectors.jsx" />
      <circle cx="80" cy="79" r="9" stroke="#05293D" strokeWidth="4" data-sentry-element="circle" data-sentry-source-file="Connectors.jsx" />
      <circle cx="96" cy="56" r="7" stroke="#05293D" strokeWidth="4" data-sentry-element="circle" data-sentry-source-file="Connectors.jsx" />
      <circle cx="97" cy="104" r="9" stroke="#05293D" strokeWidth="4" data-sentry-element="circle" data-sentry-source-file="Connectors.jsx" />
      <circle cx="113" cy="79" r="9" stroke="#05293D" strokeWidth="4" data-sentry-element="circle" data-sentry-source-file="Connectors.jsx" />
      <path d="M121.722 38.5192L123.245 37.2225L121.722 38.5192ZM27 74.0978C27 61.0175 31.7444 49.0505 39.6087 39.816L36.5634 37.2225C28.1061 47.1534 23 60.0318 23 74.0978H27ZM79.9041 127.002C50.686 127.002 27 103.316 27 74.0978H23C23 105.525 48.4769 131.002 79.9041 131.002V127.002ZM132.808 74.0978C132.808 103.316 109.122 127.002 79.9041 127.002V131.002C111.331 131.002 136.808 105.525 136.808 74.0978H132.808ZM120.2 39.816C128.064 49.0505 132.808 61.0175 132.808 74.0978H136.808C136.808 60.0318 131.702 47.1534 123.245 37.2225L120.2 39.816ZM116.039 34H43.7692V38H116.039V34ZM123.245 37.2225C121.414 35.0728 118.73 34 116.039 34V38C117.694 38 119.213 38.6573 120.2 39.816L123.245 37.2225ZM39.6087 39.816C40.5955 38.6573 42.1145 38 43.7692 38V34C41.0784 34 38.3941 35.0728 36.5634 37.2225L39.6087 39.816Z" fill="#05293D" data-sentry-element="path" data-sentry-source-file="Connectors.jsx" />
    </svg>;
};
export default Connectors;