const Range = () => {
  return <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Range" data-sentry-source-file="Range.jsx">
      <rect width="160" height="160" rx="8" fill="#4C78EC" fillOpacity="0.5" data-sentry-element="rect" data-sentry-source-file="Range.jsx" />
      <path d="M32.6768 70L23.0738 79.603" stroke="#05293D" strokeWidth="4" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="Range.jsx" />
      <path d="M127.845 89.4531L137.448 79.8501" stroke="#05293D" strokeWidth="4" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="Range.jsx" />
      <path d="M32.6025 89.4531L22.9995 79.8501" stroke="#05293D" strokeWidth="4" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="Range.jsx" />
      <path d="M127.919 70L137.522 79.603" stroke="#05293D" strokeWidth="4" strokeLinecap="square" data-sentry-element="path" data-sentry-source-file="Range.jsx" />
      <path d="M55 79.8965H24" stroke="#05293D" strokeWidth="4" data-sentry-element="path" data-sentry-source-file="Range.jsx" />
      <path d="M137 80H106" stroke="#05293D" strokeWidth="4" data-sentry-element="path" data-sentry-source-file="Range.jsx" />
      <rect x="55" y="69" width="51" height="22" stroke="#05293D" strokeWidth="4" data-sentry-element="rect" data-sentry-source-file="Range.jsx" />
    </svg>;
};
export default Range;