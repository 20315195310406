const Driving = () => {
  return <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Driving" data-sentry-source-file="Driving.jsx">
      <rect width="160" height="160" rx="8" fill="#4C78EC" fillOpacity="0.5" data-sentry-element="rect" data-sentry-source-file="Driving.jsx" />
      <path d="M79.9695 92.7573C86.9985 92.7573 92.6967 87.0591 92.6967 80.03C92.6967 73.0009 86.9985 67.3027 79.9695 67.3027C72.9404 67.3027 67.2422 73.0009 67.2422 80.03C67.2422 87.0591 72.9404 92.7573 79.9695 92.7573Z" stroke="#05293D" strokeWidth="4" strokeMiterlimit="10" data-sentry-element="path" data-sentry-source-file="Driving.jsx" />
      <path d="M80.5 132C109.495 132 133 108.495 133 79.5C133 50.5051 109.495 27 80.5 27C51.5051 27 28 50.5051 28 79.5C28 108.495 51.5051 132 80.5 132Z" stroke="#05293D" strokeWidth="4" strokeMiterlimit="10" data-sentry-element="path" data-sentry-source-file="Driving.jsx" />
      <line x1="28" y1="80" x2="67" y2="80" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="Driving.jsx" />
      <line x1="93" y1="80" x2="132" y2="80" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="Driving.jsx" />
      <line x1="80" y1="93" x2="80" y2="132" stroke="#05293D" strokeWidth="4" data-sentry-element="line" data-sentry-source-file="Driving.jsx" />
    </svg>;
};
export default Driving;